import { useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { RecordContext } from '../components/record-context';

export default function useAnalytics() {
  const { id } = useParams();
  const { record } = useContext(RecordContext);

  const analyticsTrackEvent = useCallback(
    (event, opts = {}) => {
      if (window.htevents) {
        const properties = opts.properties;
        const context = opts.context;

        window.htevents.track(event, properties, { context: context });
      }
    },
    [window.analytics]
  );

  const track = useCallback(
    (eventName, props) => {
      analyticsTrackEvent(eventName, {
        properties: {
          ...props,
          capture_id: id,
          user_id: record.authorId,
          user_uuid: record.uuid,
        },
        context: {
          groupId: record.authorAccountId,
        },
      });
    },
    [id, record]
  );

  return {
    captureStepAdded: ({ stepId }) => {
      track('capture_step_added', { step_id: stepId });
    },
    captureStepDeleted: ({ stepId }) => {
      track('capture_step_deleted', { step_id: stepId });
    },
    captureCreated: () => {
      track('capture_created', {});
    },
    captureEdited: () => {
      track('capture_edited', {});
    },
    captureExported: ({ destination }) => {
      track('capture_exported', { destination: destination });
    },
  };
}
