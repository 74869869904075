import makeStyles from '@material-ui/styles/makeStyles';

export const useStyles = makeStyles({
  cropperWrapper: {
    '& .reactEasyCrop_CropArea': {
      boxShadow: 'none !important',
      border: 'none',
    },
  },
});
