import { createTheme } from '@material-ui/core';

export const PRIMARY_MAIN = '#6A28EA';
export const SECONDARY_MAIN = '#E5D8FF';

const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif',
    color: '#010341',
    h1: {
      fontWeight: 800,
      fontSize: 40,
      lineHeight: '1.2',
      letterSpacing: 0.25,
    },
    h2: {
      fontWeight: 'bold',
      fontSize: '22px',
      lineHeight: '33px',
    },
    h3: {
      fontSize: 24,
      lineHeight: '1.25',
      fontWeight: 800,
    },
    h4: {
      fontSize: 18,
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: 24,
      lineHeight: '1.25',
    },
    body1: {
      fontSize: 18,
      lineHeight: '1.28',
      letterSpacing: '0.15px',
    },
  },
  palette: {
    primary: {
      light: PRIMARY_MAIN,
      main: PRIMARY_MAIN,
      dark: PRIMARY_MAIN,
      contrastText: '#fff',
    },
    secondary: {
      light: SECONDARY_MAIN,
      main: SECONDARY_MAIN,
      dark: SECONDARY_MAIN,
      contrastText: PRIMARY_MAIN,
    },
    text: {
      main: '#555555',
      secondary: '#8E8E8E',
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 14,
          lineHeight: '21px',
          fontWeight: 600,
          boxShadow: 'none',
          borderRadius: '4px',
          textTransform: 'none',
          border: '1.5px solid #6A28EA',
        },
      },
    },
    MuiOutlinedButton: {
      styleOverrides: {
        root: {
          padding: '7.5px 16px',
          border: '2px solid #8B4FFF',
          borderRadius: '4px',
          background: '#ffffff',
        },
      },
    },
    MuiContainedButton: {
      styleOverrides: {
        root: {
          padding: '4px 14px',
          background: 'linear-gradient(0deg, #6A28EA, #6A28EA)',
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          textTransform: 'none',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: '#FFFFFF',
          borderBottom: '2px solid #F1F1F1',
        },
      },
    },
  },
});

export default theme;
