import React, { useContext, useState } from 'react';
import { IconButton, Box, Menu, MenuItem } from '@mui/material';

import { RecordContext } from '../record-context';
import { useNotify } from '../../hooks/use-notify';
import uploadIcon from '../../images/icons/new/upload.svg';
import revertIcon from '../../images/icons/new/revert.svg';
import editIcon from '../../images/icons/new/edit.svg';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../styles';
import { updateAction } from '../action/updateAction';

export const HeroImageEditor = ({ setImageSrc, heroBg, setIsDisabledZoom }) => {
  const { record, recordDispatch } = useContext(RecordContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const [notify] = useNotify();

  const inputRef = React.useRef(null);

  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const handleInputChange = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const imageDataUrl = await readFile(file);
      setImageSrc(imageDataUrl);
    }
  };

  const triggerInputClick = () => {
    inputRef.current.click();
    setAnchorEl(null);
    setIsDisabledZoom(false);
  };

  const handleResetImage = () => {
    recordDispatch(updateAction({ ...record, customHeroImg: null, needSave: true }));
    setAnchorEl(null);
    notify('Image success reset!');
  };

  const handleEditImage = () => {
    setImageSrc(heroBg);
    setAnchorEl(null);
    setIsDisabledZoom(true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box className={classes.editor}>
      <input accept='image/*' ref={inputRef} type='file' onChange={handleInputChange} hidden />
      <IconButton aria-label='edit' className={globalClasses.iconButtonDark} onClick={handleClick}>
        <svg
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M11.65 9.63541L14.5 13.5417H5.5L7.75 10.4948L9.325 12.6042L11.5 9.63541H11.65ZM7.375 9.63541C7.975 9.63541 8.5 9.08854 8.5 8.46354C8.5 7.83854 7.975 7.29166 7.375 7.29166C6.775 7.29166 6.25 7.83854 6.25 8.46354C6.25 9.08854 6.775 9.63541 7.375 9.63541ZM17.5 5.72916V15.1042C17.5 15.9635 16.825 16.6667 16 16.6667H4C3.175 16.6667 2.5 15.9635 2.5 15.1042V5.72916C2.5 4.86979 3.175 4.16666 4 4.16666H16C16.825 4.16666 17.5 4.86979 17.5 5.72916ZM16 7.91666V5.72916H4V15.1042H16V7.91666Z'
            fill='white'
          />
        </svg>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        transformOrigin={{ vertical: 'top', horizontal: 165 }}
      >
        <MenuItem onClick={triggerInputClick}>
          <Box className={globalClasses.menuItem}>
            <img src={uploadIcon} className={globalClasses.menuItemIcon} alt='upload' />
            Upload cover image
          </Box>
        </MenuItem>
        <MenuItem onClick={handleEditImage}>
          <Box className={globalClasses.menuItem}>
            <img src={editIcon} className={globalClasses.menuItemIcon} alt='upload' />
            Edit cover image
          </Box>
        </MenuItem>
        <MenuItem onClick={handleResetImage} disableRipple>
          <Box className={globalClasses.menuItem}>
            <img src={revertIcon} className={globalClasses.menuItemIcon} alt='reset' />
            Reset cover image
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  );
};
