import makeStyles from '@material-ui/styles/makeStyles';

export const useStyles = makeStyles({
  stepNumber: {
    width: 36,
    minWidth: 36,
    height: 36,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 14,
    fontWeight: 600,
    fontSize: 20,
    color: '#393939',
    border: '2px solid #E3E3E3',
    boxSizing: 'border-box',
  },
  imageWrap: {
    overflow: 'hidden',
    marginTop: 10,
    marginBottom: 60,
    position: 'relative',
  },
  stepWrapper: {
    boxShadow: 'none',
    paddingTop: 119,
    marginTop: -119,
  },
  stepDescription: {
    paddingLeft: 53,
    marginBottom: 24,
  },
});
