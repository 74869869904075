import makeStyles from '@material-ui/styles/makeStyles';

export const useStyles = makeStyles({
  dropArea: {
    width: '100%',
    height: 310,
    backgroundColor: '#f6f6f6',
    borderRadius: 8,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    boxSizing: 'border-box',
    position: 'relative',
    marginTop: 10,
    marginBottom: 60,
    border: '1px dashed #6A28EA',
    // '&.active': {
    //   border: '1px dashed #555',
    // },
    '& p': {
      fontSize: 14,
      fontWeight: 500,
      marginBottom: 0,
      marginTop: 4,
    },
  },
  dropAreaBtn: {
    color: '#6A28EA',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  dropAreaImage: {
    marginBottom: 20,
  },
  dropAreaCloseBtn: {
    position: 'absolute !important',
    top: 16,
    right: 16,
  },
});
