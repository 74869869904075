import React, { useState, useRef, useEffect } from 'react';
import { Stage, Layer, Image } from 'react-konva';
import useImage from 'use-image';
import { StepImageBlurEditor } from './step-image-blur-editor';

export const StepImageBlur = ({ src, step, showBlurEditor, width, height }) => {
  const stageRef = useRef(null);
  const [newSrc, setNewSrc] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setNewSrc(stageRef.current.toDataURL());
    }, 800);
  }, []);

  const [sizedImage] = useImage(src, 'Anonymous');

  return (
    <>
      {newSrc !== false ? (
        <StepImageBlurEditor
          showBlurEditor={showBlurEditor}
          step={step}
          imageElement={newSrc}
          imageCover={src}
          width={width}
          height={height}
        />
      ) : (
        <Stage ref={stageRef} width={width} height={height}>
          <Layer>
            <Image image={sizedImage} width={width} height={height} />
          </Layer>
        </Stage>
      )}
    </>
  );
};
