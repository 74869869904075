import React, { useContext, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { Container } from '@material-ui/core';
import { Box, CircularProgress } from '@mui/material';

import { db } from '../../firebaseConfig/firebase.config';
import { updateAction } from '../action/updateAction';
import { useCustomParam } from '../../hooks/use-custom-param';
import { RecordContext } from '../record-context';
import { DraggableSteps } from '../drageble-steps';
import { Step } from '../step';
import { Header } from '../header';
import { Hero } from '../hero';
import { NoSteps } from '../no-steps';
import { NoPage } from '../no-page';
import { Sidebar } from '../sidebar';

import { useStyles } from './styles';
import useAnalytics from '../../analytics/useAnalytics';

export const Record = () => {
  const { id } = useParams();
  const { record, recordDispatch } = useContext(RecordContext);
  const isEdit = useCustomParam('edit');
  const classes = useStyles();
  const { captureCreated } = useAnalytics();

  useEffect(async () => {
    if (!record.arr.length && !record.isLoaded) {
      const docRef = doc(db, 'records', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        recordDispatch(
          updateAction({
            ...docSnap.data(),
            isLoaded: true,
            noSuchDocument: false,
            needSave: false,
          })
        );
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!', docSnap);
        recordDispatch(
          updateAction({
            isLoaded: true,
            noSuchDocument: true,
          })
        );
      }
    }
  });

  useEffect(() => {
    const url = new URL(window.location.href);
    const queryParams = new URLSearchParams(window.location.search);
    const sendEvent = queryParams.get('new_record') === 'true';
    if (sendEvent && record.uuid) {
      captureCreated();
      url.searchParams.delete('new_record');
      return window.history.pushState('', '', url.toString());
    }
  }, [record.uuid]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (record.needSave) {
        recordDispatch(
          updateAction({
            ...record,
            saving: true
          })
        );
        setDoc(doc(db, 'records', id), { ...record }).then(() =>
        {
          // notify('Autosave!');
          recordDispatch(
            updateAction({
              ...record,
              needSave: false,
              saving: false
            })
          );
        });
      }
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [record]);

  return (
    <Box className={classes.root}>
      <Header />
      {!record.isLoaded && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          sx={{ height: 'calc(100vh - 80px)' }}
        >
          <CircularProgress />
        </Box>
      )}
      {record.isLoaded && record.noSuchDocument && <NoPage />}
      {record.isLoaded && !record.noSuchDocument && (
        <Box pb={6} className={classes.content}>
          {isEdit && <Sidebar />}
          <Container className={classes.container}>
            <Hero />
            {isEdit ? (
              <DraggableSteps />
            ) : record.arr.length ? (
              record.arr.map((step, index) => (
                <Box className={classes.recordsContainer} key={step.id}>
                  <Step index={index} step={step} />
                </Box>
              ))
            ) : (
              <NoSteps />
            )}
          </Container>
        </Box>
      )}
    </Box>
  );
};
