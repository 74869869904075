import React, { useState, useContext, useRef } from 'react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { IconButton, Menu, MenuItem, Box } from '@mui/material';
import { RecordContext } from '../record-context';
import { useNotify } from '../../hooks/use-notify';
import { copyToClipBoard } from '../../utils';
import { updateAction } from '../action/updateAction';

import picIcon from '../../images/icons/new/picture.svg';
import uploadIcon from '../../images/icons/upload.svg';
import linkIcon from '../../images/icons/copy-grey.svg';
import deleteIcon from '../../images/icons/delete.svg';
import { useGlobalStyles } from '../../styles';

export function StepImageMenu({ step }) {
  const { record, recordDispatch } = useContext(RecordContext);
  const storage = getStorage();
  const [notify] = useNotify();
  const fileRef = useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const globalClasses = useGlobalStyles();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopy = () => {
    copyToClipBoard(
      step.imageURL,
      () => notify('Copied!'),
      () => notify('Failed to copy!', 'error')
    );
    handleClose();
  };

  const handleDelete = () => {
    const arr = record.arr;
    arr[arr.indexOf(step)].imageURL = null;
    recordDispatch(
      updateAction({
        ...record,
        arr,
        needSave: true,
      })
    );
    handleClose();
  };

  const handleLoad = async (event) => {
    const [file] = event.target.files;
    const imageRef = ref(storage, 'images/' + file.name);
    const arr = record.arr;
    setIsLoading(true);
    uploadBytesResumable(imageRef, file)
      .then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          arr[arr.indexOf(step)].imageURL = url;
          recordDispatch(
            updateAction({
              ...record,
              arr,
              needSave: true,
            })
          );
          setIsLoading(false);
          handleClose();
        });
      })
      .catch(() => {
        notify('Upload failed', 'error');
      });
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <img src={picIcon} alt='menu' />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={() => fileRef.current.click()}>
          <Box className={globalClasses.menuItem}>
            <img
              className={globalClasses.menuItemIcon + (isLoading ? ' loading' : '')}
              src={uploadIcon}
              alt='upload'
            />
            {step.imageURL ? 'Change image' : 'Upload image'}
          </Box>
        </MenuItem>
        {step.imageURL && (
          <MenuItem onClick={handleCopy}>
            <Box className={globalClasses.menuItem}>
              <img className={globalClasses.menuItemIcon} src={linkIcon} alt='copy' />
              Copy image URL
            </Box>
          </MenuItem>
        )}
        {step.imageURL && (
          <MenuItem onClick={handleDelete}>
            <Box className={globalClasses.menuItem}>
              <img className={globalClasses.menuItemIcon} src={deleteIcon} alt='delete' />
              Delete image
            </Box>
          </MenuItem>
        )}
      </Menu>
      <input
        ref={fileRef}
        onChange={handleLoad}
        multiple={false}
        type='file'
        accept='image/*'
        hidden
      />
    </div>
  );
}
