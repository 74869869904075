import React, { useContext, useState, useEffect } from 'react';

import BlurImageTool from './blur-image-tool';
import { Box, IconButton } from '@mui/material';
import cancelIcon from '../../images/icons/new/back.svg';
import submitIcon from '../../images/icons/new/check.svg';
import { useGlobalStyles } from '../../styles';
import { updateAction } from '../action/updateAction';
import { RecordContext } from '../record-context';
import { useNotify } from '../../hooks/use-notify';
import Tooltip from '@mui/material/Tooltip';
import convertBase64ToBlob from '../step-image-add-click/convertBase64ToBlob';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from '../../firebaseConfig/firebase.config';

export const StepImageBlurEditor = ({
  imageElement: element,
  step,
  showBlurEditor,
  width,
  height,
  imageCover,
}) => {
  const globalClasses = useGlobalStyles();
  const { record, recordDispatch } = useContext(RecordContext);
  const [notify] = useNotify();
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [isActiveEditor, setIsActiveEditor] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [selectedId, setSelectedId] = useState(null);
  const imageElement = document.createElement('img');
  imageElement.crossOrigin = 'anonymous';
  imageElement.src = element;

  const handleSave = () => {
    setSelectedId(false);
  };

  useEffect(() => {
    if (image !== null) {
      setIsLoading(true);
      const blob = convertBase64ToBlob(image);
      const timestamp = Number(new Date());
      const storageRef = ref(storage, `images/${timestamp}`);
      const arr = record.arr;
      uploadBytesResumable(storageRef, blob)
        .then((snapshot) => {
          getDownloadURL(snapshot.ref).then((url) => {
            arr[arr.indexOf(step)].imageURL = url;
            recordDispatch(
              updateAction({
                ...record,
                arr: arr.map((item) => ({ ...item, isDisabledEdit: false })),
                needSave: true,
              })
            );
            showBlurEditor();
            notify('Image success changed!');
            setIsLoading(false);
          });
        })
        .catch(() => {
          notify('Image change failed', 'error');
          setIsLoading(false);
        });
    }
  }, [image, selectedId]);

  useEffect(() => {
    setIsActiveEditor(' active');
  }, []);

  const handleCancel = () => {
    recordDispatch(
      updateAction({
        ...record,
        arr: record.arr.map((item) => ({ ...item, isDisabledEdit: false })),
      })
    );
    showBlurEditor();
  };

  return (
    <div>
      <BlurImageTool
        imageCover={imageCover}
        imageElement={imageElement}
        setDisabled={setDisabled}
        setImage={setImage}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        width={width}
        height={height}
      />
      <Box className={globalClasses.editorControls}>
        <Box className={globalClasses.editor + isActiveEditor}>
          <Tooltip
            arrow
            title='Cancel'
            placement='left'
            classes={{
              tooltip: globalClasses.tooltip,
              arrow: globalClasses.tooltipArrow,
            }}
          >
            <IconButton className={globalClasses.editorButton} onClick={handleCancel}>
              <img src={cancelIcon} alt='cancel' />
            </IconButton>
          </Tooltip>
          <Tooltip
            arrow
            title='Save'
            placement='left'
            classes={{
              tooltip: globalClasses.tooltip,
              arrow: globalClasses.tooltipArrow,
            }}
          >
            {isLoading ? (
              <div className={globalClasses.editorLoader} />
            ) : (
              <span>
                <IconButton
                  className={globalClasses.editorButton}
                  onClick={handleSave}
                  disabled={disabled}
                >
                  <img src={submitIcon} alt='save' />
                </IconButton>
              </span>
            )}
          </Tooltip>
        </Box>
      </Box>
    </div>
  );
};
