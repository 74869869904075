import makeStyles from '@material-ui/styles/makeStyles';

export const useStyles = makeStyles({
  label: {
    fontFamily: '"Poppins", sans-serif',
  },
  input: {
    outline: 0,
    border: 'none',
    borderBottom: '1px solid #AAAAAA',
    width: 400,
    backgroundColor: 'transparent',
  },
});
