import React from 'react';

import { deleteURLs } from '../../utils';
import { useStyles } from './styles';

export const Label = ({ label, labelFontSize, labelFontWeight, color }) => {
  const classes = useStyles();

  return (
    <label
      className={classes.label}
      style={{
        fontSize: labelFontSize || '16px',
        fontWeight: labelFontWeight || '400',
        color: color || '#555555',
      }}
    >
      {deleteURLs(label)}
    </label>
  );
};
