import React, { useContext, useState, useRef, useEffect } from 'react';
import Konva from 'konva';
import Tooltip from '@mui/material/Tooltip';
import { Stage, Layer, Image } from 'react-konva';
import { Box, IconButton } from '@mui/material';
import cancelIcon from '../../images/icons/new/back.svg';
import submitIcon from '../../images/icons/new/check.svg';
import { useGlobalStyles } from '../../styles';
import { updateAction } from '../action/updateAction';
import { RecordContext } from '../record-context';
import { useNotify } from '../../hooks/use-notify';
import useImage from 'use-image';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from '../../firebaseConfig/firebase.config';
import convertBase64ToBlob from './convertBase64ToBlob';

export const StepImageAddClick = ({
  src,
  step,
  showAddCursorEditor,
  setShowAddCursorEditor,
  width,
  height,
}) => {
  const stageRef = useRef(null);
  const imageRef = useRef(null);
  const globalClasses = useGlobalStyles();
  const [isDragging, setIsDragging] = useState(false);
  const [blink, setBlink] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { record, recordDispatch } = useContext(RecordContext);
  const [notify] = useNotify();
  const [image] = useImage(src, 'Anonymous');
  const [clickIconImage] = useImage(
    'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMzMiIHZpZXdCb3g9IjAgMCAzMyAzMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTYuNSIgY3k9IjE2LjUiIHI9IjE2LjUiIGZpbGw9ImJsYWNrIi8+CjxwYXRoIGQ9Ik0yNC44NTc4IDE0LjYxMzVMOS4yODcyNyA5LjAxMjQ0QzkuMjA4OTMgOC45ODQ2NSA5LjEyMTY1IDkuMDA0MjMgOS4wNjI5IDkuMDYzMTRDOS4wMDQxNCA5LjEyMTkgOC45ODQ1NSA5LjIwOTMxIDkuMDEyNjMgOS4yODc1MUwxNC42MTI5IDI0Ljg1NzlDMTQuNjQwNiAyNC45MzQ1IDE0LjcwOTEgMjQuOTg4OSAxNC43OTAyIDI0Ljk5ODZDMTQuNzk4NSAyNC45OTk2IDE0LjgwNyAyNSAxNC44MTU1IDI1QzE0Ljg4NzggMjUgMTQuOTU1MiAyNC45NjM2IDE0Ljk5NTEgMjQuOTAzMkwxOC4xNDQzIDIwLjEyMzZMMjIuNjUxNSAyNC42MDQ2QzIyLjczNTQgMjQuNjg4MyAyMi44NzEyIDI0LjY4ODMgMjIuOTU1MiAyNC42MDQ2TDI0LjYwNDEgMjIuOTU1N0MyNC42ODc3IDIyLjg3MTcgMjQuNjg3NyAyMi43MzU5IDI0LjYwNDEgMjIuNjUyTDIwLjEyMzggMTguMTQ0NEwyNC45MDM0IDE0Ljk5NThDMjQuOTcxMyAxNC45NTEgMjUuMDA4MSAxNC44NzE2IDI0Ljk5ODUgMTQuNzkxQzI0Ljk4OSAxNC43MTAyIDI0LjkzNDUgMTQuNjQxNiAyNC44NTgxIDE0LjYxNEwyNC44NTc4IDE0LjYxMzVaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K'
  );

  useEffect(() => {
    if (!blink) {
      return;
    }
    const period = 300;

    if (showAddCursorEditor) {
      const anim = new Konva.Animation((frame) => {
        imageRef.current.opacity((Math.sin(frame.time / period) + 1.8) / 2);
      }, imageRef.current.getLayer());

      anim.start();
      return () => {
        if (imageRef.current) {
          imageRef.current.opacity(1);
        }
        anim.stop();
      };
    }
  }, [blink, showAddCursorEditor]);

  const handleDragStart = () => {
    setIsDragging(true);
    setBlink(false);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const handleSave = () => {
    setIsLoading(true);
    const blob = convertBase64ToBlob(stageRef.current.toDataURL({ pixelRatio: 2 }));
    const timestamp = Number(new Date());
    const storageRef = ref(storage, `images/${timestamp}`);
    const arr = record.arr;
    uploadBytesResumable(storageRef, blob)
      .then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          arr[arr.indexOf(step)].imageURL = url;
          recordDispatch(
            updateAction({
              ...record,
              arr: arr.map((item) => ({ ...item, isDisabledEdit: false })),
              needSave: true,
            })
          );
          setShowAddCursorEditor();
          notify('Image success changed!');
          setIsLoading(false);
        });
      })
      .catch(() => {
        notify('Image change failed', 'error');
        setIsLoading(false);
      });
  };

  const handleCancel = () => {
    recordDispatch(
      updateAction({
        ...record,
        arr: record.arr.map((item) => ({ ...item, isDisabledEdit: false })),
      })
    );
    setShowAddCursorEditor();
  };

  return (
    <div>
      {showAddCursorEditor && (
        <Stage ref={stageRef} width={width} height={height}>
          <Layer>
            <Image image={image} width={width} height={height} />
            <Image
              ref={imageRef}
              image={clickIconImage}
              x={30}
              y={30}
              draggable
              scaleX={isDragging ? 1.05 : 1}
              scaleY={isDragging ? 1.05 : 1}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              onMouseEnter={(e) => {
                const container = e.target.getStage().container();
                container.style.cursor = 'pointer';
              }}
              onMouseLeave={(e) => {
                const container = e.target.getStage().container();
                container.style.cursor = 'default';
              }}
            />
          </Layer>
        </Stage>
      )}

      <Box className={globalClasses.editorControls}>
        <Box className={globalClasses.editor + (showAddCursorEditor ? ' active' : '')}>
          <Tooltip
            arrow
            title='Back'
            placement='left'
            classes={{
              tooltip: globalClasses.tooltip,
              arrow: globalClasses.tooltipArrow,
            }}
          >
            <IconButton className={globalClasses.editorButton} onClick={handleCancel}>
              <img src={cancelIcon} alt='cancel' />
            </IconButton>
          </Tooltip>
          <Tooltip
            arrow
            title='Save'
            placement='left'
            classes={{
              tooltip: globalClasses.tooltip,
              arrow: globalClasses.tooltipArrow,
            }}
          >
            {isLoading ? (
              <div className={globalClasses.editorLoader} />
            ) : (
              <span>
                <IconButton
                  className={globalClasses.editorButton}
                  onClick={handleSave}
                  disabled={blink}
                >
                  <img src={submitIcon} alt='save' />
                </IconButton>
              </span>
            )}
          </Tooltip>
        </Box>
      </Box>
    </div>
  );
};
