import React, { useContext, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useStyles } from './styles';
import { IconButton } from '@mui/material';
import { RecordContext } from '../record-context';
import { getStorage, getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { updateAction } from '../action/updateAction';
import { useNotify } from '../../hooks/use-notify';
import dragEndDropIcon from '../../images/icons/new/drag-n-drop.svg';
import closeIcon from '../../images/icons/new/close.svg';

export const StepDropzone = ({ step, setShowDropzone }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const { record, recordDispatch } = useContext(RecordContext);
  const [notify] = useNotify();
  const storage = getStorage();
  const onDrop = useCallback((acceptedFiles) => {
    const [file] = acceptedFiles;
    const imageRef = ref(storage, 'images/' + file.name);
    const arr = record.arr;
    setIsLoading(true);
    uploadBytesResumable(imageRef, file)
      .then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          arr[arr.indexOf(step)].imageURL = url;
          recordDispatch(
            updateAction({
              ...record,
              arr,
              needSave: true,
            })
          );
          setIsLoading(false);
        });
      })
      .catch(() => {
        notify('Upload failed', 'error');
      });
  }, []);

  const { getRootProps, getInputProps, open, isDragActive, fileRejections } = useDropzone({
    onDrop,
    noClick: true,
    accept: {
      'image/jpeg': ['.jpeg', '.png'],
    },
    maxSize: 2000000,
  });

  const hideDropzone = () => {
    setShowDropzone(false);
  };

  return (
    <div {...getRootProps()} className={classes.dropArea + (isDragActive ? ' active' : '')}>
      <IconButton className={classes.dropAreaCloseBtn} onClick={hideDropzone}>
        <img src={closeIcon} alt='menu' />
      </IconButton>
      <input {...getInputProps()} />
      <img src={dragEndDropIcon} className={classes.dropAreaImage} alt='Drag end drop' />
      {isLoading ? (
        <p>Loading...</p>
      ) : isDragActive ? (
        <p>Drag your image here</p>
      ) : (
        <p>
          Drag your image here to start uploading or{' '}
          <span className={classes.dropAreaBtn} onClick={open}>
            choose file
          </span>
        </p>
      )}
      {fileRejections.length ? (
        <p style={{ color: '#f00' }}>
          Image should have .jpeg or .png type or size should be less than 2Mb. Try again!
        </p>
      ) : (
        ''
      )}
    </div>
  );
};
