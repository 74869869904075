import * as React from 'react';
import { useReducer } from 'react';

export const RecordContext = React.createContext();

let initialState = {
  title: 'Website Workflow',
  arr: [],
  needSave: false,
  userDashboard: '',
  isLoaded: false,
  noSuchDocument: null,
  mobileOpen: false,
};

function simpleReducer(state, action) {
  const { type, ...rest } = action;
  switch (type) {
    case 'UPDATE_ATTRIBUTE':
      return { ...state, ...rest };
    default:
      throw new Error(`${action.type} not implemented in simple reducer.`);
  }
}

export function RecordContextProvider(props) {
  let [record, recordDispatch] = useReducer(simpleReducer, initialState);

  return (
    <RecordContext.Provider value={{ record, recordDispatch }}>
      {props.children}
    </RecordContext.Provider>
  );
}
