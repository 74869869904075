import makeStyles from '@material-ui/styles/makeStyles';

export const useStyles = makeStyles({
  root: {
    maxWidth: 300,
    margin: '0 auto',
    textAlign: 'center',
  },
  decor: {
    marginTop: 108,
    marginBottom: 64,
  },
  subtitle: {
    maxWidth: 300,
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 700,
    fontSize: 36,
    color: '#252525',
    marginBottom: 16,
  },
});
