import React, { useState, useContext } from 'react';
import { Box, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { RecordContext } from '../record-context';
import zoomIcon from '../../images/icons/new/zoom.svg';
import submitIcon from '../../images/icons/new/check.svg';
import cancelIcon from '../../images/icons/new/close-red.svg';
import cursorIcon from '../../images/icons/new/add-cursor.svg';
import blurIcon from '../../images/icons/new/blur.svg';
import { useGlobalStyles } from '../../styles';
import { updateAction } from '../action/updateAction';

export const StepImageEditor = ({
  showBlurEditor,
  showAddCursorEditor,
  showCropperEditor,
  disabled,
  id,
}) => {
  const [showEditorControls, setShowEditorControls] = useState(false);
  const globalClasses = useGlobalStyles();
  const { record, recordDispatch } = useContext(RecordContext);

  const hideEditor = () => {
    setShowEditorControls(false);
    recordDispatch(
      updateAction({
        ...record,
        arr: record.arr.map((step) => ({ ...step, isDisabledEdit: false })),
      })
    );
  };

  const showEditor = () => {
    setShowEditorControls(true);
    recordDispatch(
      updateAction({
        ...record,
        arr: record.arr.map((step) => {
          step.isDisabledEdit = step.id !== id;
          return step;
        }),
      })
    );
  };

  return (
    <Box className={globalClasses.editorControls}>
      <Box className={globalClasses.editor + (showEditorControls ? ' active' : '')}>
        <Tooltip
          arrow
          title='Cancel'
          placement='left'
          classes={{
            tooltip: globalClasses.tooltip,
            arrow: globalClasses.tooltipArrow,
          }}
        >
          <IconButton className={globalClasses.editorButton} onClick={hideEditor}>
            <img src={cancelIcon} alt='cancel' />
          </IconButton>
        </Tooltip>
        <Tooltip
          arrow
          title='Add cursor click'
          placement='left'
          classes={{
            tooltip: globalClasses.tooltip,
            arrow: globalClasses.tooltipArrow,
          }}
        >
          <IconButton className={globalClasses.editorButton} onClick={showAddCursorEditor}>
            <img src={cursorIcon} alt='add cursor' />
          </IconButton>
        </Tooltip>
        <Tooltip
          arrow
          title='Blur zone'
          placement='left'
          classes={{
            tooltip: globalClasses.tooltip,
            arrow: globalClasses.tooltipArrow,
          }}
        >
          <IconButton className={globalClasses.editorButton} onClick={showBlurEditor}>
            <img src={blurIcon} alt='blur' />
          </IconButton>
        </Tooltip>
        <Tooltip
          arrow
          title='Zoom'
          placement='left'
          classes={{
            tooltip: globalClasses.tooltip,
            arrow: globalClasses.tooltipArrow,
          }}
        >
          <IconButton className={globalClasses.editorButton} onClick={showCropperEditor}>
            <img src={zoomIcon} alt='zoom' />
          </IconButton>
        </Tooltip>
        <Tooltip
          arrow
          title='Save'
          placement='left'
          classes={{
            tooltip: globalClasses.tooltip,
            arrow: globalClasses.tooltipArrow,
          }}
        >
          <IconButton className={globalClasses.editorButton} onClick={hideEditor}>
            <img src={submitIcon} alt='ok' />
          </IconButton>
        </Tooltip>
      </Box>
      {!showEditorControls && (
        <IconButton
          className={globalClasses.iconButtonDark}
          onClick={showEditor}
          disabled={disabled}
          style={{ position: 'absolute', zIndex: 300, bottom: 0 }}
        >
          <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M3.22102 13.228C3.34494 12.7943 3.59277 12.3916 3.90256 12.0818L13.3202 2.6642C14.0946 1.88973 15.3648 1.88973 16.1392 2.6642L17.3474 3.87238C17.4404 3.96531 17.5333 4.08923 17.5952 4.18217C18.1219 4.95664 18.029 6.00992 17.3474 6.69146L7.92982 16.1091C7.89884 16.14 7.83688 16.171 7.8059 16.233C7.49611 16.4808 7.15535 16.6667 6.7836 16.7906L3.03515 17.8749C2.78732 17.9678 2.50851 17.9058 2.32263 17.689C2.10578 17.5031 2.04382 17.2243 2.10578 16.9765L3.22102 13.228ZM3.93354 16.0781L6.34989 15.3656C6.41185 15.3346 6.50479 15.3036 6.56675 15.2726L5.4515 15.0558C5.20367 14.9938 4.98682 14.8079 4.95584 14.5601L4.73899 13.4449C4.70801 13.5068 4.67703 13.5998 4.64605 13.6617L3.93354 16.0781ZM7.68199 14.2503L13.9707 7.9616L12.019 6.0409L5.76129 12.3296L6.07108 13.9096L7.68199 14.2503Z'
              fill='white'
            />
          </svg>
        </IconButton>
      )}
    </Box>
  );
};
