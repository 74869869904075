export const uid = () => {
  return (
    String.fromCharCode(Math.floor(Math.random() * 26) + 97) +
    Math.random().toString(16).slice(2) +
    Date.now().toString(16).slice(4)
  );
};

export const copyToClipBoard = (text, resolve, reject) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      resolve();
    })
    .catch((err) => {
      reject();
    });
};

export const detectURLs = (str) => {
  const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  return str.match(urlRegex);
};

export const deleteURLs = (str) => {
  if (!str) return;
  var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  return str.replace(urlRegex, '');
};
