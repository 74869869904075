import React, { useContext, useState } from 'react';

import { RecordContext } from '../record-context';
import { EditableLabel } from '../editable-label';

import { useStyles } from './styles';
import { updateAction } from '../action/updateAction';
import { Link } from 'react-scroll';

export const SidebarStepItem = ({ id, number, name, dragArea, isDraggable }) => {
  const classes = useStyles();
  const [showEditIcon, setShowEditIcon] = useState(false);
  const { record, recordDispatch } = useContext(RecordContext);

  const handleSaveName = (text) => {
    const arr = record.arr.map((item) => (item.id === id ? { ...item, name: text } : item));
    recordDispatch(updateAction({ ...record, arr, needSave: true }));
  };

  return (
    <div
      className={classes.stepItem + (isDraggable ? ' is-draggable' : '')}
      key={id}
      onMouseEnter={() => setShowEditIcon(true)}
      onMouseLeave={() => setShowEditIcon(false)}
      {...dragArea}
    >
      <Link to={id} activeClass='active' className={classes.stepItemLink} offset={-30} smooth spy>
        <svg
          className={classes.dndIcon + ' dnd-icon'}
          width='9'
          height='12'
          viewBox='0 0 9 12'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M2.45044 8.23962C2.98755 8.23962 3.427 8.67908 3.427 9.21619V10.3881C3.427 10.9496 2.98755 11.3646 2.45044 11.3646H1.27856C0.717041 11.3646 0.302002 10.9496 0.302002 10.3881V9.21619C0.302002 8.67908 0.717041 8.23962 1.27856 8.23962H2.45044ZM7.13794 8.23962C7.67505 8.23962 8.1145 8.67908 8.1145 9.21619V10.3881C8.1145 10.9496 7.67505 11.3646 7.13794 11.3646H5.96606C5.40454 11.3646 4.9895 10.9496 4.9895 10.3881V9.21619C4.9895 8.67908 5.40454 8.23962 5.96606 8.23962H7.13794ZM1.27856 7.45837C0.717041 7.45837 0.302002 7.04333 0.302002 6.48181V5.30994C0.302002 4.77283 0.717041 4.33337 1.27856 4.33337H2.45044C2.98755 4.33337 3.427 4.77283 3.427 5.30994V6.48181C3.427 7.04333 2.98755 7.45837 2.45044 7.45837H1.27856ZM7.13794 4.33337C7.67505 4.33337 8.1145 4.77283 8.1145 5.30994V6.48181C8.1145 7.04333 7.67505 7.45837 7.13794 7.45837H5.96606C5.40454 7.45837 4.9895 7.04333 4.9895 6.48181V5.30994C4.9895 4.77283 5.40454 4.33337 5.96606 4.33337H7.13794ZM1.27856 3.55212C0.717041 3.55212 0.302002 3.13708 0.302002 2.57556V1.40369C0.302002 0.866577 0.717041 0.427124 1.27856 0.427124H2.45044C2.98755 0.427124 3.427 0.866577 3.427 1.40369V2.57556C3.427 3.13708 2.98755 3.55212 2.45044 3.55212H1.27856ZM7.13794 0.427124C7.67505 0.427124 8.1145 0.866577 8.1145 1.40369V2.57556C8.1145 3.13708 7.67505 3.55212 7.13794 3.55212H5.96606C5.40454 3.55212 4.9895 3.13708 4.9895 2.57556V1.40369C4.9895 0.866577 5.40454 0.427124 5.96606 0.427124H7.13794Z'
            fill='#AAAAAA'
          />
        </svg>
        {number}&nbsp;
        <EditableLabel
          color='#555'
          editButtonTooltipText='Edit title'
          fullLabelWidth
          handleSave={handleSaveName}
          label={name}
          labelFontSize='14px'
          labelFontWeight='inherit'
          lightBg={true}
          inputFontSize='14px'
          inputFontWeight='normal'
          showEditButton={showEditIcon}
          showButtonHoverBg={false}
          onFocusEdit={false}
          isTruncatedLabel
        />
      </Link>
    </div>
  );
};
