import React, { useRef, useState, useEffect } from 'react';
import { Box, IconButton } from '@material-ui/core';
import TextareaAutosize from 'react-textarea-autosize';

import { deleteURLs } from '../../utils';
import lightEditIcon from '../../images/icons/edit-light.svg';
import darkEditIcon from '../../images/icons/new/edit-dark.svg';
import { useStyles } from './styles';
import { useGlobalStyles } from '../../styles';
import { useIsMount } from '../../hooks/use-is-mount';
import Tooltip from '@mui/material/Tooltip';

const ENTER_KEY_CODE = 13;
const DEFAULT_LABEL_PLACEHOLDER = '';

export const EditableLabel = (props) => {
  const {
    label,
    handleSave,
    labelFontSize,
    labelFontWeight,
    inputFontSize,
    inputFontWeight,
    showInputBorder = true,
    showEditButton = true,
    showEditButtonBg = false,
    showButtonHoverBg = true,
    color,
    placeholder = '',
    lightBg = true,
    isFocused = true,
    needIcon = true,
    onFocusEdit = true,
    fullLabelWidth = false,
    editButtonTooltipText = '',
    setIsFocusedLabel,
    isTruncatedLabel = false,
  } = props;
  const [isEditing, setEditing] = useState(false);
  const [isShowTooltip, setShowTooltip] = useState(false);
  const [text, setText] = useState(label || DEFAULT_LABEL_PLACEHOLDER);
  const isMount = useIsMount();
  const textareaRef = useRef(null);
  const labelRef = useRef(null);
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const isTextValueValid = () => typeof text != 'undefined' && text.trim().length > 0;

  const handleFocus = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    //Move cursor to the end of textarea after focus
    setTimeout(() => {
      textareaRef.current &&
        textareaRef.current.setSelectionRange(
          textareaRef.current.value.length,
          textareaRef.current.value.length
        );
    }, 0);

    if (isEditing) {
      if (isTextValueValid()) {
        setEditing(!isEditing);
      } else {
        setText(DEFAULT_LABEL_PLACEHOLDER);
        setEditing(false);
      }
      handleSave(text);
    } else {
      setEditing(true);
    }
  };

  const handleChange = (e) => setText(e.target.value);

  const handleKeyDown = (e) => {
    if (e.keyCode === ENTER_KEY_CODE && e.shiftKey) {
      return false;
    }
    if (e.keyCode === ENTER_KEY_CODE) {
      handleFocus();
    }
  };

  const labelText = isTextValueValid() ? text : DEFAULT_LABEL_PLACEHOLDER;

  useEffect(() => {
    if (isMount) {
      !label && setEditing(true);
    } else {
      isFocused && setEditing(true);
    }
  }, [isFocused]);

  useEffect(() => {
    setText(label);
  }, [label]);

  useEffect(() => {
    setText(label);
  }, [label]);

  useEffect(() => {
    setIsFocusedLabel && setIsFocusedLabel(isEditing);
  }, [isEditing]);

  useEffect(() => {
    if (labelRef.current && isTruncatedLabel) {
      labelRef.current.offsetWidth < labelRef.current.scrollWidth
        ? setShowTooltip(true)
        : setShowTooltip(false);
    }
  }, [isEditing, text]);

  return (
    <>
      {isEditing ? (
        <TextareaAutosize
          ref={textareaRef}
          value={text}
          className={classes.textarea}
          onChange={handleChange}
          onBlur={handleFocus}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          autoFocus
          style={{
            padding: 0,
            fontSize: inputFontSize || '16px',
            fontWeight: inputFontWeight || '400',
            color: color || '#555555',
            borderBottom: showInputBorder ? '1px solid #AAA' : 'none',
          }}
        />
      ) : (
        <Box className={classes.labelWrapper} style={{ width: fullLabelWidth ? '100%' : 'auto' }}>
          <Box mr={1}>
            <Tooltip
              arrow
              disableInteractive
              title={isTruncatedLabel && isShowTooltip ? deleteURLs(labelText) : ''}
              placement='top'
              classes={{
                tooltip: globalClasses.tooltip,
                arrow: globalClasses.tooltipArrow,
                transition: globalClasses.transition,
              }}
              PopperProps={{ disablePortal: true }}
            >
              <label
                className={classes.label + (isTruncatedLabel ? ' truncated' : '')}
                ref={labelRef}
                onClick={onFocusEdit ? handleFocus : () => 'do nothing!'}
                style={{
                  fontSize: labelFontSize || '16px',
                  fontWeight: labelFontWeight || '400',
                  color: color || '#555555',
                }}
              >
                {deleteURLs(labelText)}
              </label>
            </Tooltip>
          </Box>
          {needIcon && showEditButton && (
            <Tooltip
              arrow
              title={editButtonTooltipText}
              placement='top'
              classes={{
                tooltip: globalClasses.tooltip,
                arrow: globalClasses.tooltipArrow,
                tooltipPlacementTop: globalClasses.tooltipTopOffset,
              }}
            >
              <IconButton
                onClick={handleFocus}
                className={
                  classes.editIcon +
                  (!showButtonHoverBg ? ' no-hover-bg' : '') +
                  (showEditButtonBg ? ' show-bg' : '')
                }
              >
                <img src={lightBg ? darkEditIcon : lightEditIcon} alt='icon' />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      )}
    </>
  );
};
