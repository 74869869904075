import React, { useContext } from 'react';
import { IconButton, Menu, MenuItem, Box } from '@mui/material';

import { useNotify } from '../../hooks/use-notify';
import { RecordContext } from '../record-context';
import { updateAction } from '../action/updateAction';
import { uid } from '../../utils';

import menuIcon from '../../images/icons/new/three-dot.svg';
import copyIcon from '../../images/icons/new/duplicate.svg';
import deleteIcon from '../../images/icons/new/delete.svg';
import { useGlobalStyles } from '../../styles';
import useAnalytics from '../../analytics/useAnalytics';

export function StepMenu({ step, index }) {
  const { captureStepDeleted } = useAnalytics();
  const { record, recordDispatch } = useContext(RecordContext);
  const [notify] = useNotify();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const globalClasses = useGlobalStyles();

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleCopy = () => {
    const arr = record.arr;
    arr.splice(index, 0, { ...step, id: uid() });
    recordDispatch(
      updateAction({
        ...record,
        arr,
        needSave: true,
      })
    );
    handleClose();
  };

  const handleDelete = () => {
    const arr = record.arr;
    const step = arr[index];
    captureStepDeleted({ stepId: step.id });
    arr.splice(index, 1);
    recordDispatch(
      updateAction({
        ...record,
        arr,
        needSave: true,
      })
    );
    handleClose();
    notify('Capture step deleted', 'error');
  };

  return (
    <div>
      <IconButton aria-label='more' onClick={handleClick}>
        <img src={menuIcon} alt='menu' />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleCopy}>
          <Box className={globalClasses.menuItem}>
            <img className={globalClasses.menuItemIcon} src={copyIcon} alt='menu' />
            Duplicate capture step
          </Box>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <Box className={globalClasses.menuItem}>
            <img className={globalClasses.menuItemIcon} src={deleteIcon} alt='menu' />
            Delete capture step
          </Box>
        </MenuItem>
      </Menu>
    </div>
  );
}
