import makeStyles from '@material-ui/styles/makeStyles';

export const useStyles = makeStyles({
  title: {
    marginBottom: '16px',
  },
  subtitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 16,
    lineHeight: '24px',
    marginBottom: 24,
    color: '#555',
    '@media (max-width: 767px)': {
      flexDirection: 'column',
    },
  },
  hero: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    backgroundRepeat: 'no-repeat',
    paddingTop: '33.33%',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1,
      background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 30.9%, rgba(0, 0, 0, 0.1) 100%)',
    },
  },
  heroImage: {
    position: 'absolute',
    objectFit: 'cover',
    objectPosition: 'center',
    width: '100%',
    height: '100%',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  heroFooter: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '-45px auto 45px',
    maxWidth: 922,
    minHeight: 170,
    padding: '35px 20px',
    backgroundColor: '#fff',
    textAlign: 'center',
    position: 'relative',
    zIndex: 10,
    boxSizing: 'border-box',
    '@media (min-width: 1024px)': {
      marginRight: 77,
      marginLeft: 77,
      maxWidth: '100%',
      width: 'width: calc(100% - 154px);',
    },
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      margin: 'auto',
      width: 58,
      height: 1,
      backgroundColor: '#8E8E8E',
    },
  },
});
