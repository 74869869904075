import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDrezgC6HmbYcm-lJrz5RiJXqXuuvZdntM",
  authDomain: "trainual-capture.firebaseapp.com",
  projectId: "trainual-capture",
  storageBucket: "trainual-capture.appspot.com",
  messagingSenderId: "709528461758",
  appId: "1:709528461758:web:2ed2addfc5dbf41f1cd9ef",
  measurementId: "G-ZC0NBQJJJJ"
};

// const firebaseConfig = {
//   apiKey: "AIzaSyByEEBYSIO5qb01us4ifCdHsjup56ilOhM",
//   authDomain: "srewhow.firebaseapp.com",
//   databaseURL: "https://srewhow-default-rtdb.firebaseio.com",
//   projectId: "srewhow",
//   storageBucket: "srewhow.appspot.com",
//   messagingSenderId: "34958223868",
//   appId: "1:34958223868:web:7a09a2cdc4d52b973f2a79",
//   measurementId: "G-P8FGEKBXL0"
// };

const firebase = initializeApp(firebaseConfig);

const db = getFirestore(firebase);
const storage = getStorage(firebase);

export { firebase, db, storage };