import makeStyles from '@material-ui/styles/makeStyles';

export const useStyles = makeStyles({
  button: {
    padding: '0 12px !important',
    minWidth: 'max-content !important',
    backgroundColor: '#EFE7FF !important',
    borderRadius: '50px !important',
  },
});
