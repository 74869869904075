import makeStyles from '@material-ui/styles/makeStyles';

export const useStyles = makeStyles({
  root: {
    display: (props) => (props.isHide ? 'none' : 'flex'),
    alignItems: 'center',
  },
  buttonWrapper: {
    transition: '0.2s',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      display: 'block',
      transition: '0.2s',
      '& path, & circle': {
        transition: '0.2s',
      },
    },
    '&:hover': {
      transform: 'scale(1.2)',
      marginLeft: 6,
      '& svg': {
        '& path': {
          fill: '#393939',
        },
        '& circle': {
          stroke: '#393939',
        },
      },
      '& #button-text': {
        opacity: 1,
        left: 0,
      },
    },
  },
  button: {
    cursor: 'pointer',
    border: 'none',
    padding: 0,
    backgroundColor: 'transparent',
    marginRight: '12px',
    display: 'inline-block',
  },
  buttonText: {
    position: 'relative',
    fontSize: '13px',
    opacity: 0,
    transition: '0.2s',
    left: '-10px',
  },
});
