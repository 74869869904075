import makeStyles from '@material-ui/styles/makeStyles';

export const useStyles = makeStyles({
  editor: {
    position: 'absolute',
    top: 20,
    right: 20,
    zIndex: 3,
  },
});
