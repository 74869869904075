import React, { useRef, useState, useEffect } from 'react';
import { Stage, Layer, Image } from 'react-konva';
import { v4 as uuidv4 } from 'uuid';
import useImage from 'use-image';

import BlurShape from './blur-image-shape';

const BlurImageTool = ({
  imageCover,
  imageElement,
  setImage,
  height,
  width,
  setDisabled,
  selectedId,
  setSelectedId,
}) => {
  const containerRef = useRef(null);
  const stageRef = useRef(null);
  const [shapes, setShapes] = useState([]);
  const [draggingShape, setDraggingShape] = useState(null);

  const [imageCoverFormatted] = useImage(imageCover, 'Anonymous');

  function handleAddMask() {
    const pointerPosition = stageRef.current.getPointerPosition();
    if (!pointerPosition) {
      return null;
    }

    const _shapes = shapes.slice();
    const id = uuidv4();
    _shapes.push({
      id,
      x: pointerPosition.x,
      y: pointerPosition.y,
      width: 0,
      height: 0,
    });
    setShapes(_shapes);

    return id;
  }

  function selectShape(id) {
    setSelectedId(id);

    if (!id) {
      return;
    }

    // Place selected shape at the top.
    const _shapes = shapes.slice();
    const index = _shapes.findIndex((shape) => shape.id === id);
    if (index > -1) {
      const shape = _shapes[index];
      _shapes.splice(index, 1);
      _shapes.push(shape);
      setShapes(_shapes);
    }
  }

  function handleRemove(id) {
    const _shapes = shapes.slice();
    const index = _shapes.findIndex((shape) => shape.id === id);
    if (index > -1) {
      _shapes.splice(index, 1);
      setShapes(_shapes);
    }
  }

  const handleDragStart = (e) => {
    const clickedOnEmpty = e.target.index === 0;
    if (!clickedOnEmpty) {
      return;
    }
    selectShape(null);

    const id = handleAddMask();
    setDraggingShape(id);
  };

  const handleDragMove = () => {
    if (!draggingShape) {
      return;
    }

    const pointerPosition = stageRef.current.getPointerPosition();
    if (!pointerPosition) {
      return;
    }

    const _shapes = shapes.slice();
    const shape = _shapes[_shapes.length - 1];
    shape.width = pointerPosition.x - shape.x;
    shape.height = pointerPosition.y - shape.y;
    setShapes(_shapes);
  };

  const handleDragEnd = () => {
    setDisabled(false);
    // setImage(stageRef.current.toDataURL())

    if (!draggingShape) {
      // setImage(stageRef.current.toDataURL())
      return;
    }

    // Remove mask if it's too tiny.
    const _shapes = shapes.slice();
    const shape = _shapes[_shapes.length - 1];
    if (Math.abs(shape.width) < 5 && Math.abs(shape.height) < 5) {
      _shapes.pop();
      setShapes(_shapes);
    } else {
      selectShape(draggingShape);
    }

    setDraggingShape(null);
  };

  useEffect(() => {
    if (selectedId === false) {
      setImage(stageRef.current.toDataURL({ pixelRatio: 2 }));
    }
  }, [selectedId]);

  return (
    <div ref={containerRef}>
      <Stage
        width={width}
        height={height}
        ref={stageRef}
        onMouseDown={handleDragStart}
        onMouseMove={handleDragMove}
        onMouseUp={handleDragEnd}
        style={{ cursor: 'crosshair' }}
      >
        <Layer>
          <Image image={imageCoverFormatted} width={width} height={height} />
          {shapes.map((shape, i) => {
            return (
              <BlurShape
                key={shape.id}
                shape={shape}
                imageElement={imageElement}
                isSelected={shape.id === selectedId}
                onRemove={() => handleRemove(shape.id)}
                onSelect={() => selectShape(shape.id)}
                onChange={(newAttrs) => {
                  const _shapes = shapes.slice();
                  _shapes[i] = newAttrs;
                  setShapes(_shapes);
                }}
              />
            );
          })}
        </Layer>
      </Stage>
    </div>
  );
};

export default BlurImageTool;
