import React, { useContext, useState, useEffect, useCallback, useRef } from 'react';
import { Box, IconButton } from '@mui/material';

import { SiteButton } from '../buttons/site-button';
import { EditableLabel } from '../editable-label';
import { StepImageCropper } from '../step-image-cropper';
import { StepImageBlur } from '../step-image-blur/step-image-blur';
import { StepImageAddClick } from '../step-image-add-click';
import { StepImageEditor } from '../step-image-editor';
import { updateAction } from '../action/updateAction';
import { RecordContext } from '../record-context';
import { useCustomParam } from '../../hooks/use-custom-param';

import { Label } from '../label';
import { StepMenu } from '../step-menu';
import { StepImageMenu } from '../step-image-menu';
import { StepSeparator } from '../step-separator';
import { StepDropzone } from '../step-dropzone';
import { detectURLs } from '../../utils';
import { useStyles } from './styles';
import descIcon from '../../images/icons/new/text.svg';

export const Step = ({ step, index, dragArea, isDraggable, setIsDragDisabled, id }) => {
  const siteBtnListRef = useRef(null);
  const classes = useStyles();
  const isEdit = useCustomParam('edit');
  const isIframe = useCustomParam('iframe');
  const { record, recordDispatch } = useContext(RecordContext);

  const [showEditor, setShowEditor] = useState({
    showBlurEditor: false,
    showAddCursorEditor: false,
    showCropperEditor: false,
  });
  const [siteBtnListWidth, setSiteBtnListWidth] = useState(0);
  const [needDesc, setNeedDesc] = useState(false);
  const [descFocus, setDescFocus] = useState(false);
  const [showDropzone, setShowDropzone] = useState(true);
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const [isFocusedLabel, setIsFocusedLabel] = useState(false);
  const { showBlurEditor, showAddCursorEditor, showCropperEditor } = showEditor;
  const isShowEditor = showBlurEditor || showAddCursorEditor || showCropperEditor;

  const imageRef = useCallback(
    (node) => {
      if (node !== null && step.imageURL) {
        setTimeout(() => {
          setHeight(node.getBoundingClientRect().height);
          setWidth(node.getBoundingClientRect().width);
        }, 1500);
      }
    },
    [step.imageURL]
  );

  useEffect(() => {
    siteBtnListRef.current && setSiteBtnListWidth(siteBtnListRef.current.offsetWidth);
  }, [siteBtnListRef.current]);

  useEffect(() => {
    if (isEdit) {
      if (showCropperEditor || showBlurEditor || showAddCursorEditor) {
        setIsDragDisabled(true);
      } else {
        setIsDragDisabled(false);
      }
    }
  }, [showCropperEditor, showBlurEditor, showAddCursorEditor, isDraggable]);

  const handleSave = (text) => {
    const arr = record.arr.map((item) => (item.id === step.id ? { ...item, name: text } : item));
    recordDispatch(updateAction({ ...record, arr, needSave: true }));
  };

  const handleSaveDesc = (desc) => {
    const arr = record.arr.map((item) => (item.id === step.id ? { ...item, desc: desc } : item));
    setNeedDesc(false);
    recordDispatch(updateAction({ ...record, arr, needSave: true }));
  };

  const onEditDescClick = () => {
    setNeedDesc(true);
    setDescFocus(!descFocus);
  };

  const hideEditors = () => {
    setShowEditor({
      showBlurEditor: false,
      showAddCursorEditor: false,
      showCropperEditor: false,
    });
  };

  const screenWidth = document.body.clientWidth || document.body.offsetWidth;

  return (
    <Box>
      {isEdit && <StepSeparator index={index} isHide={isDraggable} />}
      <Box id={step.id} mt={3} mb={3} key={index} {...dragArea}>
        <Box className={classes.stepWrapper}>
          <Box>
            <Box py={1} display='flex' justifyContent='space-between'>
              <Box display='flex' alignItems='center' width={'calc(100% - 124px)'}>
                <Box className={classes.stepNumber}>{index + 1}</Box>
                <Box
                  display='flex'
                  alignItems='center'
                  mr={2}
                  minHeight={48}
                  width={isFocusedLabel ? `calc(100% - 50px - ${siteBtnListWidth}px)` : 'auto'}
                >
                  {isEdit ? (
                    <EditableLabel
                      color='#393939'
                      label={step.name}
                      labelFontWeight={600}
                      labelFontSize={isIframe ? 15 : 32}
                      handleSave={handleSave}
                      setIsFocusedLabel={setIsFocusedLabel}
                    />
                  ) : (
                    <Label
                      label={step.name}
                      labelFontSize={isIframe ? 18 : 32}
                      labelFontWeight={600}
                      color='#393939'
                    />
                  )}
                </Box>
                <Box className='site-btn-list' ref={siteBtnListRef}>
                  {detectURLs(step.name) &&
                    detectURLs(step.name).map((url, index) => (
                      <SiteButton key={index} url={url} withText={!(isIframe && isEdit) && screenWidth > 578} />
                    ))}
                </Box>
              </Box>
              {isEdit && (
                <Box display='flex' alignItems='center'>
                  <IconButton aria-label='Add description' onClick={onEditDescClick}>
                    <img src={descIcon} alt='desc' />
                  </IconButton>
                  <StepImageMenu step={step} index={index} />
                  <StepMenu step={step} index={index} />
                </Box>
              )}
            </Box>
            {(isEdit && step.desc) || needDesc ? (
              <Box className={classes.stepDescription}>
                <EditableLabel
                  label={step.desc || ''}
                  showInputBorder={false}
                  isFocused={descFocus}
                  placeholder='Description'
                  handleSave={handleSaveDesc}
                  needIcon={false}
                />
              </Box>
            ) : (
              step.desc && (
                <Box className={classes.stepDescription}>
                  <Label label={step.desc} />
                </Box>
              )
            )}
            {step.imageURL ? (
              <Box className={classes.imageWrap}>
                {isEdit ? (
                  !isShowEditor && (
                    <>
                      <img
                        ref={imageRef}
                        style={{ width: '100%', display: 'block' }}
                        src={step.imageURL}
                        alt={step.name}
                      />
                      <StepImageEditor
                        id={id}
                        disabled={step.isDisabledEdit}
                        showAddCursorEditor={() =>
                          setShowEditor((prev) => ({ ...prev, showAddCursorEditor: true }))
                        }
                        showBlurEditor={() =>
                          setShowEditor((prev) => ({ ...prev, showBlurEditor: true }))
                        }
                        showCropperEditor={() =>
                          setShowEditor((prev) => ({ ...prev, showCropperEditor: true }))
                        }
                      />
                    </>
                  )
                ) : (
                  <img
                    ref={imageRef}
                    style={{ width: '100%' }}
                    src={step.imageURL}
                    alt={step.name}
                  />
                )}
                <StepImageCropper
                  image={step.imageURL}
                  showCropperEditor={showCropperEditor}
                  setShowCropperEditor={hideEditors}
                  step={step}
                  setIsDragDisabled={setIsDragDisabled}
                />
                {showBlurEditor && (
                  <StepImageBlur
                    src={step.imageURL}
                    showBlurEditor={hideEditors}
                    step={step}
                    width={width}
                    height={height}
                  />
                )}
                <StepImageAddClick
                  src={step.imageURL}
                  setShowAddCursorEditor={hideEditors}
                  showAddCursorEditor={showAddCursorEditor}
                  step={step}
                  width={width}
                  height={height}
                />
              </Box>
            ) : (
              showDropzone &&
              isEdit && <StepDropzone setShowDropzone={setShowDropzone} step={step} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
