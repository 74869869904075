import React from 'react';
import { Box } from '@mui/material';

import { useStyles } from './styles';
import decor from '../../images/empty-state.png';

export const NoSteps = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <img src={decor} alt='decor' className={classes.decor} />
        <Box className={classes.subtitle}>Any steps not added yet</Box>
      </Box>
    </Box>
  );
};
