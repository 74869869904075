import makeStyles from '@material-ui/styles/makeStyles';

export const useStyles = makeStyles({
  root: {
    maxWidth: 1024,
    margin: '0 auto',
  },
  decor: {
    position: 'absolute',
    right: '-212px',
    top: 0,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    maxWidth: 496,
    margin: '0 auto',
    paddingTop: 80,
    paddingBottom: 15,
    textAlign: 'center',
    position: 'relative',
    boxSizing: 'border-box',
  },
  title: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 700,
    fontSize: 152,
    color: '#393939',
    marginTop: 0,
    marginBottom: 40,
  },
  titleWrapper: {
    position: 'relative',
    paddingTop: 45,
  },
  subtitle: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 700,
    fontSize: 32,
    color: '#393939',
    marginBottom: 16,
  },
  content: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 400,
    fontSize: 18,
    color: '#393939',
  },
  btn: {
    marginTop: 56,
  },
});
