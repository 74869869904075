import React, { useContext, useState, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Box } from '@mui/material';

import { updateAction } from '../action/updateAction';
import { RecordContext } from '../record-context';
import { StepSeparator } from '../step-separator';
import { Step } from '../step';

export const DraggableSteps = () => {
  const { record, recordDispatch } = useContext(RecordContext);
  const [draggableId, setDraggableId] = useState('');
  const [isDragDisabled, setIsDragDisabled] = useState(false);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const steps = Array.from(record.arr);
    const [reorderedItem] = steps.splice(result.source.index, 1);
    steps.splice(result.destination.index, 0, reorderedItem);

    recordDispatch(updateAction({ ...record, arr: steps, needSave: true }));
    setDraggableId('');
  };

  const handleOnDragStart = (result) => {
    setDraggableId(result.draggableId);
  };

  useEffect(() => {
    recordDispatch(
      updateAction({
        ...record,
        arr: record.arr.map((step) => ({ ...step, isDisabledEdit: false })),
      })
    );
  }, []);

  return (
    <DragDropContext onDragEnd={handleOnDragEnd} onDragStart={handleOnDragStart}>
      <Droppable droppableId='characters'>
        {(provided) => (
          <Box
            className='characters'
            {...provided.droppableProps}
            ref={provided.innerRef}
            sx={{ maxWidth: 800, margin: '0 auto', paddingBottom: '50px' }}
          >
            {record.arr.length ? (
              record.arr.map((step, index) => (
                <Draggable
                  key={step.id}
                  draggableId={step.id}
                  index={index}
                  isDragDisabled={isDragDisabled}
                >
                  {(provided) => (
                    <Box ref={provided.innerRef} {...provided.draggableProps}>
                      <Step
                        index={index}
                        id={step.id}
                        isEdit={true}
                        step={step}
                        dragArea={provided.dragHandleProps}
                        isDraggable={step.id === draggableId}
                        setIsDragDisabled={setIsDragDisabled}
                      />
                    </Box>
                  )}
                </Draggable>
              ))
            ) : (
              <StepSeparator index={0} isHide={false} />
            )}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};
