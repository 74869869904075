import makeStyles from '@material-ui/styles/makeStyles';

export const useGlobalStyles = makeStyles({
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 400,
  },
  '@keyframes rotate': {
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  menuItemIcon: {
    marginRight: 8,
    '&.loading': {
      animation: `$rotate 3s linear infinite`,
    },
  },
  editorLoader: {
    width: 24,
    minWidth: 24,
    height: 24,
    minHeight: 24,
    borderRadius: '50%',
    margin: 3,
    marginTop: 7,
    border: 'solid 4px #EFE7FF',
    borderBottomColor: '#6A28EA',
    animation: `$rotate 2s linear infinite`,
    boxSizing: 'border-box',
  },
  editorControls: {
    display: 'flex',
    flexDirection: 'column',
    // minHeight: 36,
    width: 36,
    position: 'absolute',
    zIndex: 100,
    bottom: 20,
    right: 20,
    '&.top-position': {
      top: 20,
      bottom: 'inherit',
    },
  },
  editor: {
    width: 36,
    borderRadius: 36,
    backgroundColor: '#fff',
    boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)',
    padding: 0,
    boxSizing: 'border-box',
    overflow: 'hidden',
    maxHeight: 0,
    transition: '0.2s',
    opacity: 0,
    '&.active': {
      maxHeight: 200,
      padding: 3,
      opacity: 1,
    },
  },
  editorButton: {
    width: 30,
    height: 30,
    marginBottom: '4px !important',
    '&:last-child': {
      marginBottom: '0 !important',
    },
    '&.loading': {
      opacity: 0.4,
      animation: `$rotate 3s linear infinite`,
      pointsEvent: 'none',
    },
    '&:disabled': {
      opacity: 0.4,
    },
  },
  iconButtonDark: {
    width: 36,
    height: 36,
    backgroundColor: '#121212 !important',
    translate: '0.2s',
    boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)',
    '& svg': {
      '& path': {
        translate: '0.2s',
      },
    },
    '&:hover': {
      backgroundColor: '#fff !important',
      '& svg': {
        '& path': {
          fill: '#121212',
        },
      },
    },
  },

  iconButtonLight: {
    width: 36,
    height: 36,
    backgroundColor: '#fff !important',
    translate: '0.2s',
    boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)',
    '& svg': {
      '& path': {
        translate: '0.2s',
      },
    },
    '&:hover': {
      backgroundColor: '#121212 !important',
      '& svg': {
        '& path': {
          fill: '#fff',
        },
      },
    },
  },
  tooltip: {
    backgroundColor: '#121212 !important',
  },
  tooltipArrow: {
    '&:after, &:before': {
      backgroundColor: '#121212 !important',
    },
  },
  tooltipTopOffset: {
    top: 7,
  },
  editorSliderWrapper: {
    height: 88,
    marginBottom: 12,
    marginTop: 8,
  },
  slider: {
    '& .MuiSlider-thumb': {
      height: 17,
      width: 17,
      backgroundColor: '#6A28EA !important',
      '&:hover, &:active': {
        boxShadow: 'none !important',
      },
    },
    '& .MuiSlider-track': {
      border: 'none',
      boxSizing: 'border-box',
      width: 3,
    },
    '& .MuiSlider-rail': {
      opacity: 1,
      width: 3,
      backgroundColor: '#8E8E8E !important',
    },
  },
});
