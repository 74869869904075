import React, { useContext, useState, useCallback, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import { Box, IconButton, Slider } from '@mui/material';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { storage } from '../../firebaseConfig/firebase.config';

import { RecordContext } from '../record-context';
import getCroppedImg from './create-croped-image';
import { useNotify } from '../../hooks/use-notify';
import { useStyles } from './styles';
import { updateAction } from '../action/updateAction';
import { useGetImageMeta } from '../../hooks/use-get-image-meta';
import cancelIcon from '../../images/icons/new/back.svg';
import submitIcon from '../../images/icons/new/check.svg';
import Tooltip from '@mui/material/Tooltip';

import { useGlobalStyles } from '../../styles';

export const StepImageCropper = ({ image, step, setShowCropperEditor, showCropperEditor }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [size, setSize] = useState({ width: 768, height: 768 });
  const [zoom, setZoom] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({
    x: 0,
    y: 0,
    width: 796,
    height: 796,
  });
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { record, recordDispatch } = useContext(RecordContext);

  useEffect(() => {
    useGetImageMeta(image, (width, height) => {
      setCroppedAreaPixels((prev) => ({
        ...prev,
        width,
        height,
      }));
      setSize({ width, height });
    });
  }, []);

  const [notify] = useNotify();

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const getImage = async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels);

      const timestamp = Number(new Date());
      const storageRef = ref(storage, `images/${timestamp}`);
      const arr = record.arr;
      setIsLoading(true);
      uploadBytesResumable(storageRef, croppedImage)
        .then((snapshot) => {
          getDownloadURL(snapshot.ref).then((url) => {
            arr[arr.indexOf(step)].imageURL = url;
            recordDispatch(
              updateAction({
                ...record,
                arr: arr.map((item) => ({ ...item, isDisabledEdit: false })),
                needSave: true,
              })
            );
            setIsLoading(false);
            setShowCropperEditor();
            setTimeout(() => {
              setZoom(1);
            }, 1050);
            notify('Image success changed!');
          });
        })
        .catch(() => {
          notify('Image change failed', 'error');
        });
    } catch (e) {
      console.error(e);
    }
  };

  const handleCancel = () => {
    recordDispatch(
      updateAction({
        ...record,
        arr: record.arr.map((item) => ({ ...item, isDisabledEdit: false })),
      })
    );
    setShowCropperEditor();
  };

  return (
    <>
      <Box className={classes.cropperWrapper}>
        {showCropperEditor && (
          <>
            <Cropper
              aspect={size.width / size.height}
              crop={crop}
              image={image}
              objectFit='auto-cover'
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              zoom={zoom}
              showGrid
            />

            <img
              style={{ width: '100%', visibility: 'inherit' }}
              src={step.imageURL}
              alt={step.name}
            />
          </>
        )}

        <Box className={globalClasses.editorControls}>
          <Box className={globalClasses.editor + (showCropperEditor ? ' active' : '')}>
            <Tooltip
              arrow
              title='Back'
              placement='left'
              classes={{
                tooltip: globalClasses.tooltip,
                arrow: globalClasses.tooltipArrow,
              }}
            >
              <IconButton className={globalClasses.editorButton} onClick={handleCancel}>
                <img src={cancelIcon} alt='cancel' />
              </IconButton>
            </Tooltip>
            <div className={globalClasses.editorSliderWrapper}>
              <Slider
                className={globalClasses.slider}
                step={0.1}
                max={2}
                min={1}
                value={zoom}
                orientation='vertical'
                onChange={(values) => setZoom(values.target.value)}
              />
            </div>
            <Tooltip
              arrow
              title='Save'
              placement='left'
              classes={{
                tooltip: globalClasses.tooltip,
                arrow: globalClasses.tooltipArrow,
              }}
            >
              {isLoading ? (
                <div className={globalClasses.editorLoader} />
              ) : (
                <span>
                  <IconButton
                    className={globalClasses.editorButton}
                    onClick={getImage}
                    disabled={zoom === 1}
                  >
                    <img src={submitIcon} alt='save' />
                  </IconButton>
                </span>
              )}
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </>
  );
};
