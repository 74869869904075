import makeStyles from '@material-ui/styles/makeStyles';

export const useStyles = makeStyles({
  sidebar: {
    position: 'sticky',
    width: 250,
    padding: '24px 16px',
    borderRight: '1px solid #E3E3E3',
    height: 'calc(100vh - 66px)',
    boxSizing: 'border-box',
    overflowY: 'auto',
    '@media (min-width: 1200px)': {
      width: 320,
    },
    '&': {
      scrollbarWidth: 'thin',
      scrollbarColor: '#E3E3E3',
    },
    '&::-webkit-scrollbar': {
      height: 3,
      width: 3,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#fff',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#E3E3E3',
      borderRadius: 3,
      border: '1px solid #E3E3E3',
    },
  },
  title: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '30px',
    textAlign: 'center',
    color: '#6A28EA',
    marginTop: 0,
    marginBottom: 24,
  },
  subtitle: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '21px',
    color: '#555',
    marginTop: 0,
    marginBottom: 4,
  },
  captureTitleWrapper: {
    borderBottom: '1px solid #E3E3E3',
    marginBottom: 18,
    paddingBottom: 12,
  },
  stepList: {
    marginBottom: 40,
  },
  stepItem: {
    borderRadius: 4,
    transition: '0.2s',
    boxSizing: 'border-box',
    cursor: 'grab',
    textDecoration: 'none',
    border: '1px solid transparent',
    '& .dnd-icon': {
      minWidth: 9,
    },
    '&:hover, &.is-draggable': {
      boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
      border: '1px solid #e3e3e3',
      borderLeftColor: 'transparent',
      '& .dnd-icon': {
        '& path': {
          fill: '#393939',
        },
      },
    },
  },
  stepItemLink: {
    display: 'flex',
    padding: '6px 10px',
    fontSize: 14,
    lineHeight: '21px',
    color: '#555',
    boxSizing: 'border-box',
    '&.active': {
      fontWeight: 600,
    },
  },
  dndIcon: {
    marginTop: 4,
    marginRight: 8,
  },
});
