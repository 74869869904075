import makeStyles from '@material-ui/styles/makeStyles';

export const useStyles = makeStyles({
  header: {
    backgroundColor: '#F9F9F9 !important',
    boxShadow: 'none !important',
    borderBottom: 'none !important',
  },
  notification: {
    display: 'flex',
    position: 'absolute',
    top: 'calc(100% + 9px)',
    right: 0,
    width: 'max-content',
    backgroundColor: '#0094FF',
    borderRadius: 4,
    color: '#fff',
    fontSize: 14,
    lineHeight: '21px',
    padding: '10px 35px 10px 10px',
    zIndex: 1,
    boxSizing: 'border-box',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: -4,
      right: 40,
      width: 10,
      height: 10,
      backgroundColor: '#0094FF',
      transform: 'rotate(45deg)',
    },
  },
  notificationButton: {
    display: 'block',
    cursor: 'pointer',
    position: 'absolute !important',
    top: 10,
    right: 8,
    '& img': {
      width: 20,
    },
  },
});
