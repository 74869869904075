import React, { useContext, useState } from 'react';
import { Box, Button, Drawer } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { RecordContext } from '../record-context';
import { EditableLabel } from '../editable-label';
import { SidebarStepItem } from './sidebar-step-item';
import { updateAction } from '../action/updateAction';
import useAnalytics from '../../analytics/useAnalytics';
import { uid } from '../../utils';
import { useStyles } from './styles';

const drawerWidth = 250;

export const handleClickScroll = (id) => {
  const element = document.getElementById(id);
  if (element) {
    const y = element.getBoundingClientRect().top + window.pageYOffset - 20;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
};

export const Sidebar = (props) => {
  const classes = useStyles();
  const { record, recordDispatch } = useContext(RecordContext);
  const { title, arr } = record;
  const [draggableId, setDraggableId] = useState(null);
  const { captureStepAdded } = useAnalytics();
  const { window } = props;

  const container = window !== undefined ? () => window().document.body : undefined;

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const steps = Array.from(record.arr);
    const [reorderedItem] = steps.splice(result.source.index, 1);
    steps.splice(result.destination.index, 0, reorderedItem);

    recordDispatch(updateAction({ ...record, arr: steps, needSave: true }));
    setDraggableId(null);
  };

  const handleOnDragStart = (result) => {
    setDraggableId(result.draggableId);
  };

  const handleSaveTitle = (text) => {
    recordDispatch(updateAction({ ...record, title: text, needSave: true }));
  };

  const handleDrawerToggle = () => {
    recordDispatch(
      updateAction({
        ...record,
        mobileOpen: !record.mobileOpen,
      })
    );
  };

  const handleAddStep = () => {
    const stepId = uid();
    arr.splice(arr.length, 0, { name: `Step ${arr.length + 1}`, id: stepId });
    (async () => {
      recordDispatch(
        updateAction({
          ...record,
          arr,
          needSave: true,
        })
      );
    })().then(() => handleClickScroll(stepId));

    captureStepAdded({ stepId: stepId });
  };

  const drawer = (
    <Box className={classes.sidebar}>
      <h5 className={classes.title}>Outline</h5>
      <Box>
        <h6 className={classes.subtitle}>Capture name</h6>
        <div className={classes.captureTitleWrapper}>
          <EditableLabel
            label={title}
            handleSave={handleSaveTitle}
            labelFontSize='18px'
            labelFontWeight='bold'
            inputFontSize='18px'
            inputFontWeight='bold'
            showInputBorder={false}
            color='#555'
            lightBg={true}
          />
        </div>
        <h6 className={classes.subtitle}>Capture steps</h6>
        <DragDropContext onDragEnd={handleOnDragEnd} onDragStart={handleOnDragStart}>
          <Droppable droppableId='outline-step-list'>
            {(provided) => (
              <div
                className={classes.stepList}
                {...provided.droppableProps}
                ref={provided.innerRef}
                sx={{ maxWidth: 800, margin: '0 auto' }}
              >
                {arr.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <Box ref={provided.innerRef} {...provided.draggableProps}>
                        <SidebarStepItem
                          name={item.name}
                          number={index + 1}
                          id={item.id}
                          key={item.id}
                          dragArea={provided.dragHandleProps}
                          isDraggable={item.id === draggableId}
                        />
                      </Box>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Button
          variant='contained'
          fullWidth
          onClick={handleAddStep}
          startIcon={
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M15.5 7.96394C15.5 8.28846 15.2115 8.54087 14.9231 8.54087H8.57692V14.887C8.57692 15.2115 8.28846 15.5 8 15.5C7.67548 15.5 7.42308 15.2115 7.42308 14.887V8.54087H1.07692C0.752404 8.54087 0.5 8.28846 0.5 8C0.5 7.67548 0.752404 7.38702 1.07692 7.38702H7.42308V1.04087C7.42308 0.752404 7.67548 0.5 8 0.5C8.28846 0.5 8.57692 0.752404 8.57692 1.04087V7.38702H14.9231C15.2115 7.38702 15.5 7.67548 15.5 7.96394Z'
                fill='white'
              />
            </svg>
          }
        >
          Add step
        </Button>
      </Box>
    </Box>
  );

  return (
    <>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={record.mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, borderRight: 'unset', zIndex: 0 },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', top: 66, borderRight: 'unset', zIndex: 0  },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
};
