import React, { useContext, useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { Box, IconButton, Slider } from '@mui/material';
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import { storage } from '../../firebaseConfig/firebase.config';

import { RecordContext } from '../record-context';
import getCroppedImg from '../step-image-cropper/create-croped-image';
import { useNotify } from '../../hooks/use-notify';
import { useStyles } from './styles';
import Tooltip from '@mui/material/Tooltip';
import { updateAction } from '../action/updateAction';
import cancelIcon from '../../images/icons/new/back.svg';
import submitIcon from '../../images/icons/new/check.svg';

import { useGlobalStyles } from '../../styles';

export const HeroImageCropper = ({ image, setImageSrc, heroSize, isDisabledZoom }) => {
  const { width, height } = heroSize;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({ x: 0, y: 0, width, height });
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { record, recordDispatch } = useContext(RecordContext);

  const [notify] = useNotify();

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const getImage = async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels);

      const timestamp = Number(new Date());
      const storageRef = ref(storage, `images/${timestamp}`);
      setIsLoading(true);

      uploadBytes(storageRef, croppedImage)
        .then(() => {
          getDownloadURL(storageRef).then((downloadURL) => {
            recordDispatch(updateAction({ ...record, customHeroImg: downloadURL, needSave: true }));
          });
          setTimeout(() => {
            setIsLoading(false);
            setImageSrc(null);
            setZoom(1);
            notify('Image success uploaded!');
          }, 1000);
        })
        .catch(() => {
          notify('Image change failed', 'error');
        });
    } catch (e) {
      console.error(e);
    }
  };

  const handleCancel = () => {
    recordDispatch(
      updateAction({
        ...record,
        arr: record.arr.map((item) => ({ ...item, isDisabledEdit: false })),
      })
    );
    setImageSrc(null);
  };

  return (
    <>
      <Box className={classes.cropperWrapper}>
        {image && (
          <Cropper
            aspect={width / height}
            crop={crop}
            image={image}
            objectFit='auto-cover'
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            zoom={zoom}
            showGrid
          />
        )}

        <Box className={globalClasses.editorControls + ' top-position'}>
          <Box className={globalClasses.editor + (image ? ' active' : '')}>
            <Tooltip
              arrow
              title='Back'
              placement='left'
              classes={{
                tooltip: globalClasses.tooltip,
                arrow: globalClasses.tooltipArrow,
              }}
            >
              <IconButton className={globalClasses.editorButton} onClick={handleCancel}>
                <img src={cancelIcon} alt='cancel' />
              </IconButton>
            </Tooltip>
            <div className={globalClasses.editorSliderWrapper}>
              <Slider
                className={globalClasses.slider}
                step={0.1}
                max={2}
                min={1}
                value={zoom}
                orientation='vertical'
                onChange={(values) => setZoom(values.target.value)}
              />
            </div>
            <Tooltip
              arrow
              title='Save'
              placement='left'
              classes={{
                tooltip: globalClasses.tooltip,
                arrow: globalClasses.tooltipArrow,
              }}
            >
              {isLoading ? (
                <div className={globalClasses.editorLoader} />
              ) : (
                <IconButton
                  className={globalClasses.editorButton + (isLoading ? ' loading' : '')}
                  onClick={getImage}
                  disabled={zoom === 1 && isDisabledZoom}
                >
                  <img src={submitIcon} alt='save' />
                </IconButton>
              )}
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </>
  );
};
