import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import { ThemeProvider } from '@emotion/react';

import { RecordContextProvider } from './components/record-context/record-context';
import { Record } from './components/record';
import theme from './theme/custom-theme';
import { NoPage } from './components/no-page';

function App() {
  useEffect(() => {
    window.Appcues.anonymous();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <RecordContextProvider>
        <SnackbarProvider>
          <BrowserRouter>
            <Routes>
              <Route path='/record/:id' element={<Record />} />
              <Route path='*' element={<NoPage />} />
            </Routes>
          </BrowserRouter>
        </SnackbarProvider>
      </RecordContextProvider>
    </ThemeProvider>
  );
}

export default App;
