import React from 'react';
import { Box, Button } from '@mui/material';

import { useStyles } from './styles';
import decor from '../../images/decor.png';

export const NoPage = () => {
  const classes = useStyles();

  const handleOpen = () => {
    window.open(`https://trainual.com/`);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.titleWrapper}>
          <img src={decor} alt='decor' className={classes.decor} />
          <Box className={classes.title}>Oops!</Box>
        </Box>
        <Box className={classes.subtitle}>Let us help you find your way</Box>
        <Box className={classes.content}>
          Looks like the page you're looking for is (poof) gone.
        </Box>
        <Box className={classes.content}>
          But don't worry, we've got your back — and lots of other rad resources for you to check
          out!
        </Box>
        <Box className={classes.btn}>
          <Button variant='contained' onClick={handleOpen}>
            Explore Trainual
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
