import React, { useContext } from 'react';
import { Box } from '@mui/material';

import { updateAction } from '../action/updateAction';
import { RecordContext } from '../record-context';

import { uid } from '../../utils';

import { useStyles } from './styles';
import useAnalytics from '../../analytics/useAnalytics';
import { handleClickScroll } from '../sidebar';

export const StepSeparator = ({ index, isHide }) => {
  const classes = useStyles({ isHide });
  const { record, recordDispatch } = useContext(RecordContext);
  const { captureStepAdded } = useAnalytics();

  const handleAddStep = () => {
    const arr = record.arr;
    const stepId = uid();
    arr.splice(index, 0, { name: `Step ${index + 1}`, id: stepId });
    (async () => {
      recordDispatch(
        updateAction({
          ...record,
          arr,
          needSave: true,
        })
      );
    })().then(() => handleClickScroll(stepId));
    captureStepAdded({ stepId: stepId });
  };

  return (
    <Box className={classes.root}>
      <Box>
        <Box aria-label='add' className={classes.buttonWrapper}>
          <button className={classes.button} onClick={handleAddStep}>
            <svg
              width='36'
              height='36'
              viewBox='0 0 36 36'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle cx='18' cy='18' r='17.5' stroke='#C6C6C6' />
              <path
                d='M25.5 17.9639C25.5 18.2885 25.2115 18.5409 24.9231 18.5409H18.5769V24.887C18.5769 25.2115 18.2885 25.5 18 25.5C17.6755 25.5 17.4231 25.2115 17.4231 24.887V18.5409H11.0769C10.7524 18.5409 10.5 18.2885 10.5 18C10.5 17.6755 10.7524 17.387 11.0769 17.387H17.4231V11.0409C17.4231 10.7524 17.6755 10.5 18 10.5C18.2885 10.5 18.5769 10.7524 18.5769 11.0409V17.387H24.9231C25.2115 17.387 25.5 17.6755 25.5 17.9639Z'
                fill='#C6C6C6'
              />
            </svg>
          </button>
          <span className={classes.buttonText} id='button-text'>
            Add step
          </span>
        </Box>
      </Box>
    </Box>
  );
};
