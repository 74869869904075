import { useSnackbar } from 'notistack';

export function useNotify() {
  const { enqueueSnackbar } = useSnackbar();

  function notify(msg, variant = 'success', opts = {}) {
    enqueueSnackbar(msg, {
      variant,
      autoHideDuration: 2000,
      preventDuplicate: true,
      ...opts,
      anchorOrigin: { horizontal: 'center', vertical: 'top' },
    });
  }

  return [notify];
}
