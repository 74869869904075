import makeStyles from '@material-ui/styles/makeStyles';

export const useStyles = makeStyles({
  root: {
    minHeight: '100vh',
    width: '100%',
    fontFamily: '"Poppins", sans-serif',
  },
  content: {
    marginTop: 64,
    display: 'flex',
    paddingBottom: '0 !important',
  },
  container: {
    padding: 44,
    width: 'calc(100% - 320px)',
    '@media (max-width: 1200px)': {
      padding: 16,
      width: '100%',
    },
  },
  recordsContainer: {
    maxWidth: 800,
    margin: '0 auto',
    '@media (max-width: 1200px)': {
      paddingLeft: 24,
      paddingRight: 24,
      maxWidth: '100%',
    },
    '@media (max-width: 425px)': {
      padding: 0,
    },
  },
});
