import React from 'react';
import { Box, Fab } from '@mui/material';

import linkIcon from '../../../images/icons/new/link-purple.svg';
import { useStyles } from './styles';

export const SiteButton = ({ url, withText = true }) => {
  const classes = useStyles();
  const { hostname } = new URL(url);

  const handleClick = () => window.open(url, '_blank');

  return (
    <Fab
      className={classes.button}
      variant='extended'
      size='small'
      color='secondary'
      onClick={handleClick}
    >
      <Box mr={withText ? 1 : '-5px'} ml={withText ? 0 : '-5px'} mb={-1}>
        <img src={linkIcon} alt='icon' style={{ width: '20px' }} />
      </Box>
      {withText && hostname}
    </Fab>
  );
};
