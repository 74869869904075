import makeStyles from '@material-ui/styles/makeStyles';

export const useStyles = makeStyles({
  label: {
    fontFamily: '"Poppins", sans-serif',
    lineHeight: 1.1,
    whiteSpace: 'pre-wrap',
    '&.truncated': {
      display: 'block',
      width: '133px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    paddingRight: 36,
    maxWidth: '100%',
    boxSizing: 'border-box',
  },
  textarea: {
    outline: 0,
    padding: 0,
    border: 'none',
    width: '100%',
    backgroundColor: 'transparent',
    fontFamily: '"Poppins", sans-serif',
    resize: 'none',
    whiteSpace: 'pre-wrap',
  },
  editIcon: {
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%);',
    right: -5,
    height: 36,
    width: 36,
    '&:hover.no-hover-bg': {
      backgroundColor: 'transparent',
    },
    '&.show-bg': {
      backgroundColor: '#f9f9f9',
    },
  },
});
