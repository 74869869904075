import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Box, AppBar, Toolbar, Container, Button, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

// import { OutlinedButton } from '../buttons/outlined-button';
import { useCustomParam } from '../../hooks/use-custom-param';
import { useNotify } from '../../hooks/use-notify';
// import { useNotify } from "../../hooks/use-notify";
import { RecordContext } from '../record-context';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig/firebase.config';
import { useStyles } from './styles';

// import { PDFDownloadLink } from "@react-pdf/renderer";

import logo from '../../images/logo.svg';
// import share2Icon from '../../images/icons/share2.svg';
// import shareIcon from '../../images/icons/share-gray.svg';
// import downloadIcon from '../../images/icons/download.svg';
// import linkIcon from '../../images/icons/link.svg';
import saveIcon from '../../images/icons/save.svg';
// import closeIcon from '../../images/icons/new/close-white.svg';
// import PDFFile from '../../pdf/PDFFile'
import { updateAction } from '../action/updateAction';
import useAnalytics from '../../analytics/useAnalytics';
// import { useGlobalStyles } from "../../styles";

export const Header = () => {
  const classes = useStyles();
  const isEdit = useCustomParam('edit');
  const isIframe = useCustomParam('iframe');
  const nonAdminView = isEdit && isIframe;
  const { id } = useParams();
  // const [showSaveNotification, setShowSaveNotification] = useState(true);
  const { record, recordDispatch } = useContext(RecordContext);
  const [notify] = useNotify();
  // const [anchorElNav, setAnchorElNav] = React.useState(null);
  // const [ notify ] = useNotify();
  const { captureEdited, captureExported } = useAnalytics();
  // const globalClasses = useGlobalStyles();

  // const handleShare = () => {
  //   const actualWidth = document.body.clientHeight || document.body.offsetHeight;
  //   window.open(`${record.userDashboard}?open_trainual_capture_modal=true&id=${id}&height=${actualWidth}`);
  //   captureExported({ destination: 'Trainual' });
  // }
  //

  const handleDrawerToggle = () => {
    recordDispatch(
      updateAction({
        ...record,
        mobileOpen: !record.mobileOpen,
      })
    );
  };

  const setIsSaving = (isSaving) => {
    recordDispatch(
      updateAction({
        ...record,
        saving: isSaving,
      })
    );
  }

  const handleSave = async (id) => {
    (async () => {
      setIsSaving(true);
      await setDoc(doc(db, 'records', id), { ...record });
      recordDispatch(
        updateAction({
          ...record,
          needSave: false,
        })
      );
    })()
      .then(() => {
        setIsSaving(false);
        // setShowSaveNotification(true);
        notify('Changes success saved!');
        captureEdited();
      })
      .catch(() => {
        notify('Something went wrong', 'error');
        setIsSaving(false);
      });
  };

  const handleAddToTrainual = () => {
    (async () => {
      setIsSaving(true);
      await setDoc(doc(db, 'records', id), { ...record });
      recordDispatch(
        updateAction({
          ...record,
          needSave: false,
        })
      );
      captureEdited();
    })()
      .then(() => {
        setIsSaving(false);
        const actualWidth = document.body.clientHeight || document.body.offsetHeight;
        window.open(
          `${record.userDashboard}?open_trainual_capture_modal=true&id=${id}&height=${actualWidth}&title=${record.title}`
        );
        captureExported({ destination: 'Trainual' });
      })
      .catch(() => {
        notify('Something went wrong', 'error');
        setIsSaving(false);
      });
  };

  // const copyToClipBoard = async () => {
  //   try {
  //     const iframeCode = `<iframe
  //        src="https://capture.trainual.com/record/${id}?iframe=1&edit=1"
  //        class="trainual-capture-iframe"
  //        style="border: none; width: 1px; min-width: 100%; height: 800px; overflow: hidden;"></iframe>`;
  //     await navigator.clipboard.writeText(iframeCode);
  //     notify('Copied!');
  //   } catch (err) {
  //     notify('Failed to copy!', 'error');
  //   }
  // }

  // const copyCaptureLink = async () => {
  //   try {
  //     const captureLink = `https://capture.trainual.com/record/${id}?iframe=1&edit=1`;
  //     await navigator.clipboard.writeText(captureLink);
  //     notify('Copied!');
  //   } catch (err) {
  //     notify('Failed to copy!', 'error');
  //   }
  // }

  // const handleOpenNavMenu = (event) => {
  //   setAnchorElNav(event.currentTarget);
  // };
  //
  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null);
  // };

  return (
    <AppBar component='nav' className={classes.header}>
      <Container maxWidth='xl'>
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          <Box display='flex'>
            {nonAdminView && (<IconButton
              color="primary"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>)}

            <img src={logo} alt='logo' />
          </Box>
          <Box display='flex'>
            {/*{*/}
            {/*  !isIframe && (*/}
            {/*    <>*/}
            {/*      <Box mr={1}>*/}
            {/*        <OutlinedButton title='Share' icon={share2Icon} onClick={handleOpenNavMenu} />*/}
            {/*      </Box>*/}
            {/*      <Menu*/}
            {/*        id="basic-button"*/}
            {/*        anchorEl={anchorElNav}*/}
            {/*        anchorOrigin={{*/}
            {/*          vertical: 'bottom',*/}
            {/*          horizontal: 'center',*/}
            {/*        }}*/}
            {/*        keepMounted*/}
            {/*        transformOrigin={{*/}
            {/*          vertical: 'top',*/}
            {/*          horizontal: 'center',*/}
            {/*        }}*/}
            {/*        open={Boolean(anchorElNav)}*/}
            {/*        onClose={handleCloseNavMenu}*/}
            {/*        PaperProps={{*/}
            {/*          elevation: 0,*/}
            {/*          sx: {*/}
            {/*            overflow: 'visible',*/}
            {/*            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',*/}
            {/*            mt: 1,*/}
            {/*          }}}*/}
            {/*      >*/}
            {/*        {(!isIframe && isEdit) && record.needSave ?*/}
            {/*          <MenuItem disabled={true}>*/}
            {/*              <Box className={globalClasses.menuItem}>*/}
            {/*                <Box mr={1.5}><img src={downloadIcon} alt="download icon"/></Box>*/}
            {/*                  Export PDF*/}
            {/*              </Box>*/}
            {/*          </MenuItem> :*/}
            {/*          <PDFDownloadLink document={<PDFFile data={record} />} fileName="trainual-capture" style={ {textDecoration: "none", color: "#000"} }>*/}
            {/*            {({loading}) => (loading || !record.isLoaded ?*/}
            {/*              <MenuItem disabled={record.needSave}>*/}
            {/*                  <Box className={globalClasses.menuItem}>*/}
            {/*                    <Box mr={1.5}><img src={downloadIcon} alt="download icon"/></Box>*/}
            {/*                      Loading...*/}
            {/*                  </Box>*/}
            {/*              </MenuItem> :*/}
            {/*              <MenuItem disabled={record.needSave}>*/}
            {/*                  <Box className={globalClasses.menuItem}>*/}
            {/*                    <Box mr={1.5}><img src={downloadIcon} alt="download icon"/></Box>*/}
            {/*                      Export PDF*/}
            {/*                  </Box>*/}
            {/*              </MenuItem>)}*/}
            {/*          </PDFDownloadLink>*/}
            {/*          }*/}
            {/*          {!isIframe &&*/}
            {/*              <MenuItem onClick={copyToClipBoard} disabled={record.needSave}>*/}
            {/*                  <Box className={globalClasses.menuItem}>*/}
            {/*                    <Box mr={1.5}><img src={linkIcon} alt="link icon"/></Box>*/}
            {/*                      Copy iFrame code*/}
            {/*                  </Box>*/}
            {/*              </MenuItem>*/}
            {/*          }*/}

            {/*        <MenuItem onClick={handleShare} disabled={record.needSave}>*/}
            {/*          <Box className={globalClasses.menuItem}>*/}
            {/*            <Box mr={1.5}><img src={shareIcon} alt="share icon"/></Box>*/}
            {/*            Add to Trainual*/}
            {/*          </Box>*/}
            {/*        </MenuItem>*/}
            {/*        {isEdit &&*/}
            {/*          <MenuItem onClick={copyCaptureLink} disabled={record.needSave}>*/}
            {/*            <Box className={globalClasses.menuItem}>*/}
            {/*              <Box mr={1.5}><img src={linkIcon} alt="link icon"/></Box>*/}
            {/*              Copy Capture link*/}
            {/*            </Box>*/}
            {/*          </MenuItem>*/}
            {/*        }*/}
            {/*      </Menu>*/}
            {/*    </>*/}
            {/*  )*/}
            {/*}*/}

            {nonAdminView && record.arr.length && (
              <Box>
                {record.needSave ? (
                  <Box position='relative'>
                    <Button variant='contained' onClick={() => handleSave(id)}>
                      {record.saving ? 'Autosaving...' : 'Save changes'}
                    </Button>
                    {/*{!record.saving && showSaveNotification && (*/}
                    {/*  <Box className={classes.notification}>*/}
                    {/*    You have unsaved changes. Save now?*/}
                    {/*    <div*/}
                    {/*      className={classes.notificationButton}*/}
                    {/*      onClick={() => setShowSaveNotification(false)}*/}
                    {/*    >*/}
                    {/*      <img src={closeIcon} alt='close notification' />*/}
                    {/*    </div>*/}
                    {/*  </Box>*/}
                    {/*)}*/}
                  </Box>
                ) : (
                  <Button variant='contained' startIcon={<img src={saveIcon} alt='icon' />}>
                    Saved
                  </Button>
                )}
              </Box>
            )}
            {isEdit && !isIframe && (
              <Button variant='contained' onClick={handleAddToTrainual}>
                {record.saving ? 'Autosaving...' : 'Add to Trainual'}
              </Button>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
