import React, { useContext, useEffect, useState, useRef } from 'react';
import { Box } from '@mui/material';

import { RecordContext } from '../record-context';
import { useCustomParam } from '../../hooks/use-custom-param';
import { EditableLabel } from '../editable-label';
import { Label } from '../label';
import { SiteButton } from '../buttons/site-button';
import { updateAction } from '../action/updateAction';
import { HeroImageEditor } from '../hero-image-editor';
import arrow from '../../images/icons/arrow.svg';
import stepsIcon from '../../images/icons/steps.svg';
import userIcon from '../../images/icons/user.svg';
import { HeroImageCropper } from '../hero-image-cropper';

import { useStyles } from './styles';
import { detectURLs } from '../../utils';

export const Hero = () => {
  const [links, setLinks] = useState([]);
  const [imageSrc, setImageSrc] = useState(null);
  const [isDisabledZoom, setIsDisabledZoom] = useState(false);
  const [heroSize, setHeroSize] = useState({ width: 0, height: 0 });
  const [showEditIcon, setShowEditIcon] = useState(false);
  const { record, recordDispatch } = useContext(RecordContext);
  const { title, author, arr, customHeroImg, heroImg } = record;
  const isEdit = useCustomParam('edit');
  const classes = useStyles();

  const heroRef = useRef(null);

  const handleSave = (text) => {
    recordDispatch(updateAction({ ...record, title: text, needSave: true }));
  };

  const heroBg = customHeroImg ? customHeroImg : heroImg;

  useEffect(() => {
    if (record.arr.length) {
      let arr = [];
      record.arr.forEach((step) => {
        const stepLinks = detectURLs(step.name);
        if (stepLinks) {
          arr = [].concat(arr, stepLinks);
        }
      });
      setLinks(arr);
    }
  }, [record.arr]);

  useEffect(() => {
    setHeroSize({
      height: heroRef.current.clientHeight,
      width: heroRef.current.clientWidth,
    });
  }, []);

  return (
    <Box>
      <Box className={classes.hero} ref={heroRef}>
        {isEdit && (
          <HeroImageEditor
            heroBg={heroBg}
            setImageSrc={setImageSrc}
            setIsDisabledZoom={setIsDisabledZoom}
          />
        )}
        <HeroImageCropper
          image={imageSrc}
          setImageSrc={setImageSrc}
          heroSize={heroSize}
          isDisabledZoom={isDisabledZoom}
        />
        {!imageSrc && <img src={heroBg} className={classes.heroImage} alt='hero-bg' />}
      </Box>
      <Box className={classes.heroFooter}>
        <Box className={classes.title}>
          {isEdit ? (
            <div
              onMouseEnter={() => setShowEditIcon(true)}
              onMouseLeave={() => setShowEditIcon(false)}
            >
              <EditableLabel
                label={title}
                handleSave={handleSave}
                labelFontSize='32px'
                labelFontWeight='bold'
                inputFontSize='32px'
                inputFontWeight='bold'
                color='#393939'
                lightBg
                showEditButton={showEditIcon}
              />
            </div>
          ) : (
            <Label label={title} labelFontSize='32px' labelFontWeight='bold' color='#393939' />
          )}
        </Box>
        <Box className={classes.subtitle}>
          <Box mx={2} display='flex' flexDirection='row' alignItems='center'>
            <img src={stepsIcon} alt='steps' />
            &nbsp;
            {`${arr.length} steps`}
          </Box>
          <Box mx={2} display='flex' flexDirection='row' alignItems='center'>
            <img src={userIcon} alt='user' />
            &nbsp;
            {`Created by ${author}`}
          </Box>
        </Box>
        {links.length ? (
          <Box display='flex' flexWrap='wrap' justifyContent='center'>
            {links.map((item, index) => (
              <Box key={index} mb={1} display='flex' alignItems='center'>
                <SiteButton url={item} />
                {index < links.length - 1 && (
                  <Box mr={1} ml={1}>
                    <img src={arrow} alt='arrow' />
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        ) : (
          ''
        )}
      </Box>
    </Box>
  );
};
